import axios from 'axios'
import request from '@/utils/request'
const fetch = (
    path = '',
    params = {},
    restConfig = {
        method: 'post',
        'Content-Type': 'application/json'
    }
) =>
    request('distributionServer', path, {
        params,
        ...restConfig
    })
export const getPartnerInfoPage = (query) => {
    // 渠道主体
    return fetch('/partner/partner-info-page', query, {
        dataType: 'form'
    })
}
export const getPartnerInfo = () => {
    // 渠道主体
    return axios.fetch('distributionServer', '/distribution/partnerInfo-list')
}
// 获取虚拟和实际分销商
export const getDistributors = () => {
    return fetch('/distribution/distributor-info-list-with-virtual')
}

export const getActulDistributors = () => {
    return fetch('/distribution/distributor-info-list')
}

export const getWeekNew = (params) => {
    return fetch('/distribution/distributor-week-new')
}
// 连日下滑
export const getDownTwoMore = (params) => {
    return fetch('/distribution/distributor-down-two-more')
}
// 昨日销量下滑
export const getDownYesterday = (params) => {
    return fetch('/distribution/distributor-down-yesterday')
}
// 分销商产品日报列表
export const getDistributionSkuReportList = (params) => {
    return fetch('/distribution/distributor-spu-report', params)
}
// 分销商日报列表
export const getDistributionDailyReport = (params) => {
    return fetch('/distribution/distributor-daily-report', params)
}

// 供应商列表
export const getPartnerInfoList = (params) => {
    return fetch('/partner/partner-info-list', params)
}
