import axios from 'axios'

// 文档 https://i.goolboo.com/wiki/pages/viewpage.action?pageId=1081615

export default {
    productBrandCreate(query) {
        // 4.2数据导入第一步，检查excel列名
        return axios.fetch('productServer', '/product-brand/product-brand-create', query, {
            'Content-Type': 'application/json',
        })
    },
    getBrands(){
      // 获取品牌
      return axios.fetch('productServer', '/product-brand/product-brand-list')
    }
}
