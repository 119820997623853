<template>
    <div class="gb-chart-query" :class="{'gb-chart-query-mini': isMini}">
        <div class="gb-chart-query-group">
            <!-- todo:数据字典 -->
            <el-select
                v-model="channelId"
                class="w240 mr40 mb20"
                placeholder="渠道筛选"
                :filterable="true"
            >
                <el-option
                    v-for="item in channels"
                    :key="item.id"
                    :label="item.channelName"
                    :value="item.id"
                >
                </el-option>
            </el-select>
            <div class="custom-radio-group mb20 mr40">
                <div
                    v-for="(item, name) in dateGroup"
                    :key="name"
                    class="custom-radio-item"
                    :class="{ 'radio-checked': name === checkedName }"
                    @click.stop.capture="handleChangeDate(name)"
                    @mouseenter="handleEnterDate(name)"
                    @mouseleave="handleLeaveDate(name)"
                >
                    {{ item.title }}
                    <DatePicker
                        v-model="item.value"
                        :open.sync="item.visible"
                        :type="name"
                        :default-value="item.defalut"
                        :range="name === 'date'"
                        :popup-class="`${name}Popup`"
                        @close="handleCalendarChange($event, name)"
                    ></DatePicker>
                </div>
                <span class="date-view">{{ viewDate }}</span>
            </div>
            <div class="goods-alias-group mb20 mr40">
                <div class="goods-alias-group-list">
                    <div
                        v-for="item in favoriteSpuList"
                        :key="item.itemId"
                        class="goods-alias-group-list-item"
                        :class="{ 'radio-checked': item.itemId === favorite }"
                        @click="handleChangeSpu(item)"
                    >
                        <el-tooltip
                            class="item"
                            effect="dark"
                            :content="item.fullName"
                            placement="top"
                        >
                            <span>{{ item.spuAlias }}</span>
                        </el-tooltip>
                    </div>
                    <div
                        v-if="!isMini"
                        class="goods-alias-group-list-item"
                        :class="{ 'radio-checked': !favorite }"
                        @click="handleChangeSpu()"
                    >
                        其他
                    </div>
                </div>
                <el-select
                    v-if="!isMini"
                    v-model="keyword"
                    class="w240 search-input"
                    filterable
                    remote
                    reserve-keyword
                    placeholder="搜索商品信息"
                    :remote-method="querySearchAsync"
                    :loading="loading"
                >
                    <el-option
                        v-for="item in searchList"
                        :key="item.id"
                        class="custom-select-item"
                        :label="item.spuAlias"
                        :value="item.id"
                        @click.native="handleOption($event, item)"
                    >
                        <span>{{ item.spuAlias }}</span>
                        <div>
                            <el-tag
                                v-if="item.platformSpuNo"
                                size="mini"
                            >
                                平台商品Id：{{ item.platformSpuNo }}
                            </el-tag>
                            <el-tag
                                v-if="item.spuAlias"
                                size="mini"
                            >
                                商品简称：{{ item.spuAlias }}
                            </el-tag>
                            <el-tag
                                v-if="item.channelName"
                                size="mini"
                            >
                                渠道：{{ item.channelName }}
                            </el-tag>
                        </div>
                    </el-option>
                </el-select>
            </div>
            <el-select
                v-if="!isMini"
                v-model="form.targetType"
                class="w240 mr40 mb20"
                placeholder="请选择板块"
                :filterable="true"
                @clear="handleSearch"
            >
                <el-option
                    v-for="(item, key) in templates"
                    :key="key"
                    :label="item"
                    :value="key"
                    @click.native="handleSearch"
                >
                </el-option>
            </el-select>
        </div>
        <div v-if="!isMini" class="gb-chart-query-btns">
            <el-button
                type="primary"
                round
                class="custom-btn"
                @click="handleSearch"
            >
                查询
            </el-button>
            <el-button round class="custom-btn" @click="handleReset">重置</el-button>
        </div>
    </div>
</template>
<script>
import moment from 'moment'
import { mapState } from 'vuex'
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css'
import 'vue2-datepicker/locale/zh-cn'
import customerSvc from 'services/customer'
import sellSvc from 'services/sell'

moment.locale('zh-cn', {
    week: {
        dow: 1 // Monday is the first day of the week
    }
}) // 本地化设置一周第一天为周一 旧有写法lang

export default {
    name: 'OperationChartQuery',
    components: {
        DatePicker
    },
    props: {
        isMini: {
            type: Boolean,
            defalut: false
        }
    },
    data () {
        return {
            form: {
                spuId: null,
                startDay: null,
                endDay: null,
                targetType: '0'
            },
            formClone: {
                spuId: null,
                startDay: null,
                endDay: null,
                targetType: '0'
            },
            channelId: null, // 渠道ID
            dateGroup: {
                date: {
                    title: '日',
                    value: null,
                    defalut: null,
                    visible: false
                },
                week: {
                    title: '周',
                    value: null,
                    default: null,
                    visible: false
                },
                month: {
                    title: '月',
                    value: null,
                    default: null,
                    visible: false
                }
            }, // 日期
            newDate: null, // 记录页面进入时候的初始化日期
            checkedName: '', // 选中radio名称
            timer: null, // 时间器
            favoriteSpuList: [], // 关注商品
            favorite: null, // 选中商品
            loading: false,
            searchList: [],
            keyword: '',
            templates: {
                0: '全部',
                1: '竞品对比分析'
            }
        }
    },
    computed: {
        viewDate () {
            const checkedName = this.checkedName
            if (!checkedName) {
                // 未选初始化上周
                return `${this.getPrevWeek('start')}至${this.getPrevWeek(
                    'end'
                )}`
            }
            let viewDate
            const checkedVal = this.dateGroup[checkedName].value
            if (checkedName === 'date') {
                // 选中日
                viewDate = checkedVal.map(item => this.getCurrentDate(item))
            } else if (['week', 'month'].includes(checkedName)) {
                // 选中周末
                viewDate = ['start', 'end'].map(item =>
                    this.getCurrentMonthOrWeek(item, checkedVal, checkedName)
                )
            }
            let [start, end] = viewDate
            return start === end ? start : `${start}至${end}`
        },
        ...mapState({
            channels: state => state.customer.channels
        })
    },
    watch: {
        channels: {
            immediate: true,
            handler (newVal) {
                if (newVal && newVal[0]) {
                    this.channelId = this.channelId || newVal[0].id
                }
            }
        },
        channelId: {
            immediate: true,
            handler (newVal) {
                if (newVal) {
                    this.getFavoriteSpuList()
                }
            }
        },
        favoriteSpuList: {
            immediate: true,
            handler (newVal) {
                if (!newVal || !newVal.length) {
                    this.favorite = null
                    this.form.spuId = null
                    this.handleSearch()
                    return
                }
                this.favorite = newVal[0].itemId
                this.handleSearch()
            }
        }
    },
    created () {
        this.newDate = new Date()
        this.setInitDate()
    },
    mounted () {},
    methods: {
        handleReset () {
            Object.keys(this.form).forEach(item => {
                this.form[item] = null
            })
            this.form.targetType = '0'
            this.keyword = ''
            this.searchList = []
            this.checkedName = ''
            this.form.spuId = null
            this.favorite =
                this.favoriteSpuList && this.favoriteSpuList.length
                    ? this.favoriteSpuList[0].id
                    : null
            this.channelId = this.channels[0].id
            // this.handleSearch()
        },
        handleSearch () {
            // const {
            //     spuId,
            //     targetType
            // } = this.form
            // if(!this.form.spuId) {
            //     // this.$message.warning('请选择商品，如未关注商品，点击右上角快捷入口进入商品页面添加关注')
            //     return
            // }
            if (this.favorite) {
                this.form.spuId = this.favorite
            }
            const [startDay, endDay] = this.viewDate.split('至')
            this.form.startDay = startDay
            this.form.endDay = endDay || startDay
            this.$emit('handle-search', this.form, this.channelId)
        },
        getPrevWeek (type) {
            // 获取上一周
            return moment()
                .week(moment().week() - 1)
                [`${type}Of`]('week')
                .format('YYYY-MM-DD')
        },
        getCurrentWeek (type, date) {
            // 获取当前周
            return this.getCurrentMonthOrWeek(type, date, 'week')
        },
        getCurrentMonth (type, date) {
            // 获取当前月
            return this.getCurrentMonthOrWeek(type, date, 'month')
        },
        getCurrentMonthOrWeek (type, date, dateType) {
            // 获取当前周或当前月
            return moment(date || new Date())
                [`${type}Of`](dateType)
                .format('YYYY-MM-DD')
        },
        getCurrentDate (date) {
            // 获取当前日期
            return moment(date).format('YYYY-MM-DD')
        },
        setInitDate (assignName) {
            // 初始化日期
            Object.keys(this.dateGroup)
                .filter(item => item !== assignName)
                .forEach(item => {
                    if (item === 'date') {
                        this.dateGroup[item].value = [
                            this.newDate,
                            this.newDate
                        ]
                        this.dateGroup[item].default = [
                            this.newDate,
                            this.newDate
                        ]
                    } else {
                        this.dateGroup[item].value = this.newDate
                        this.dateGroup[item].default = this.newDate
                    }
                })
        },
        handleChangeDate (name, isFilterName) {
            // 设置日周月选择状态并初始化选择值，isFilterName是否开启过滤名字
            this.checkedName = name
            this.setInitDate(isFilterName ? name : null)
            this.handleSearch()
        },
        handleEnterDate (name) {
            // 鼠标进入日月周选择
            this.dateGroup[name].visible = true
            this.$nextTick(() => {
                this.listenerDatePannels(name)
            })
        },
        handleLeaveDate (name) {
            // 鼠标离开日月周选择
            this.timer = setTimeout(() => {
                this.listenerDatePannels(name, 'remove')
                this.dateGroup[name].visible = false
            }, 300)
        },
        listenerDatePannels (name, methodType = 'add') {
            // 监听展开板鼠标移动
            const selector = document.querySelector(`.${name}Popup`)
            if (!selector) {
                return
            }
            selector[`${methodType}EventListener`]('mouseleave', (e) => {
                e.stopPropagation()
                this.dateGroup[name].visible = false
            })
            selector[`${methodType}EventListener`]('mouseenter', (e) => {
                e.stopPropagation()
                clearTimeout(this.timer)
                this.dateGroup[name].visible = true
            })
        },
        handleCalendarChange (event, name) {
            // 点击面板触发
            this.handleChangeDate(name, true)
        },
        handleChangeSpu (item) {
            if (!item) {
                this.favorite = null
                return
            }
            this.form.spuId = null
            this.keyword = ''
            this.searchList = []
            this.favorite = item.itemId
            this.handleSearch()
        },
        async getFavoriteSpuList () {
            const { data = [], total = 0 } = await sellSvc.favoriteList({
                channelId: this.channelId
            })
            this.favoriteSpuList = data.map(item => {
                item.fullName = item.spuAlias
                // item.spuAlias =
                //     item.spuAlias && item.spuAlias.length > 8
                //         ? `${item.spuAlias.substr(0, 8)}..`
                //         : item.spuAlias
                return item
            })
        },
        async querySearchAsync (queryString) {
            if (!queryString || !this.channelId) {
                return
            }
            this.loading = true
            // 远程搜索
            const query = {
                current: 1, // 当前页
                size: 10, // 显示数量
                enabled: true, // 启用状态
                // foeFlag: true, // 竞品
                platformSpuNoOrSpuAliasLike: queryString, // 渠道名称
                channelIds: [this.channelId]
            }
            const { data = [], total = 0 } = await sellSvc.getChannelSpuList(
                query
            )
            this.loading = false
            this.searchList = data
        },
        handleOption (e, item) {
            this.form.spuId = item.id
            this.favorite = null
            this.handleSearch()
        }
    }
}
</script>
<style lang="scss" scoped>
.gb-chart-query {
    display: flex;
    justify-content: space-between;
    width: 100%;

    .w240 {
        width: 240px;
    }

    .mr40 {
        margin-right: 40px;
    }

    .mb20 {
        margin-bottom: 20px;
    }

    &-group {
        display: flex;
        flex: 1;
        flex-wrap: wrap;
    }

    .custom-radio-group {
        display: flex;
        align-items: center;
    }

    .custom-radio-item {
        position: relative;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 48px;
        height: 40px;
        margin-right: 10px;
        font-size: 14px;
        font-weight: 400;
        color: #606266;
        cursor: pointer;
        border: 1px solid #dcdfe6;
        border-radius: 1px;

        &:hover {
            color: #3595e1;
            border: 1px solid #3595e1;
        }

        .mx-datepicker {
            position: absolute;
            top: 0;
            left: 0;
            z-index: -1;
            width: 100%;
            height: 100%;
            opacity: 0;
        }
    }

    .goods-alias-group {
        display: inline-flex;
    }

    .goods-alias-group-list {
        display: inline-flex;
    }

    .goods-alias-group-list-item {
        box-sizing: border-box;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        max-width: 100px;
        height: 40px;
        padding: 0 5px;
        margin-right: 5px;
        overflow: hidden;
        font-size: 12px;
        font-weight: 400;
        color: #606266;
        cursor: pointer;
        border: 1px solid #dcdfe6;
        border-radius: 1px;

        & > span {
            width: 100%;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }

        &:hover {
            color: #3595e1;
            border: 1px solid #3595e1;
        }
    }

    .radio-checked {
        color: #fff;
        background-color: #3595e1;
        border: 1px solid #3595e1;

        &:hover {
            color: #fff;
        }
    }

    .date-view {
        font-size: 14px;
        font-weight: 400;
        color: #606266;
    }

    &-btns {
        .el-button {
            border-radius: 8px;
        }
    }
}

.gb-chart-query-mini {
    display: flex;
    justify-content: flex-start;
    width: auto;

    .mb20 {
        margin-bottom: 0;
    }

    .mr40 {
        margin-right: 10px;
    }

    .custom-radio-item {
        width: 40px;
        margin-right: 5px;
        font-size: 12px;
    }

    .date-view {
        font-size: 12px;
    }

    .gb-chart-query-group {
        .el-select {
            display: flex;
            align-items: center;
        }

        .goods-alias-group {
            flex: 1;
        }

        .goods-alias-group-list {
            flex: 1;
            flex-wrap: wrap;
        }
    }
}

.custom-select-item {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    height: 50px;
    line-height: 50px;

    & > div,
    & > span {
        line-height: 1;
    }

    & > div {
        font-size: 12px;
        color: #b4b4b4;

        .el-tag {
            &:not(:first-of-type) {
                margin-left: 10px;
            }
            color: #b4b4b4;
        }
    }
}
</style>
