<template>
    <div class="competing-goods-analysis">
        <!-- todo:分离 -->
        <div class="competing-goods-analysis-query" v-if="hasQuery">
            <span class="query-title">竞品对比分析</span>
            <el-select
                v-model="query.lvl2VisitSourceList"
                class="w240 mr20 mb20"
                placeholder="二级流量来源选择（多选）"
                clearable
                multiple
                :multiple-limit="8"
                collapse-tags
                :filterable="true"
                @change="handleChange"
            >
                <el-option
                    v-for="item in level2"
                    :key="item.id"
                    :label="item.sourceName"
                    :value="item.id"
                >
                </el-option>
            </el-select>
            <el-select
                v-model="query.lvl1VisitSource"
                class="w240 mr20 mb20"
                placeholder="一级流量来源选择（单选）"
                clearable
                :filterable="true"
                @change="handleChange"
            >
                <el-option
                    v-for="item in level1"
                    :key="item.id"
                    :label="item.sourceName"
                    :value="item.id"
                >
                </el-option>
            </el-select>
            <div class="mr20 mb20">
                <el-button type="primary" @click="handleSearch">查询</el-button>
                <el-button @click="handleSaveFilter">保存筛选</el-button>
            </div>
            <el-radio-group
                class="mb20"
                v-model="short"
                @change="handleShortChange"
            >
                <el-tooltip
                    v-for="item in shortcuts"
                    :key="item.id"
                    class="item"
                    effect="dark"
                    :content="item.itemTitle"
                    placement="bottom"
                    :disabled="!item.itemTitle || item.itemTitle.length < 4"
                >
                    <el-radio-button
                        :label="item.id"
                        class="custom-radio-button"
                    >
                        {{ item.itemTitleCopy }}
                    </el-radio-button>
                </el-tooltip>
            </el-radio-group>
        </div>
        <div id="competing-goods-analysis-chart" v-loading="loadingChart"></div>
        <div class="competing-goods-analysis-table" v-loading="loadingTable">
            <el-table
                border
                :data="tableData.spuDataStatisticsList"
                :default-sort="defaultSort"
                style="width: 100%;"
                ref="analysisTable"
                @sort-change="handleSortChange"
            >
                <el-table-column
                    type="index"
                    align="center"
                    fixed
                    v-if="
                        tableData.spuDataStatisticsList &&
                            tableData.spuDataStatisticsList.length
                    "
                >
                    <template slot="header">
                        <i @click="showColumn" class="el-icon-s-tools"></i>
                    </template>
                </el-table-column>
                <el-table-column
                    v-for="(item, index) in colsView"
                    :prop="item.prop"
                    :label="item.label"
                    :key="index"
                    :fixed="[0, 1].includes(index)"
                    :sortable="item.prop.includes('column') ? 'custom' : false"
                    :min-width="
                        item.prop.includes('column')
                            ? item.label.length >= 9
                                ? 200
                                : item.label.length * 30
                            : 150
                    "
                    :align="item.prop.includes('column') ? 'right' : 'left'"
                >
                    <template slot-scope="scope">
                        <div
                            v-if="!item.prop.includes('column')"
                            class="custom-col-content"
                        >
                            <el-tooltip
                                class="item"
                                effect="dark"
                                :content="scope.row[item.prop]"
                                placement="top-start"
                            >
                                <span>{{ scope.row[item.prop] }}</span>
                            </el-tooltip>
                        </div>
                        <span v-if="item.prop.includes('column')">{{
                            scope.row[item.prop]
                        }}</span>
                    </template>
                </el-table-column>
                <template slot="empty">
                    <p>暂无数据</p>
                </template>
            </el-table>
        </div>
        <columnSortDia
            v-if="this.filterEmptyCol"
            ref="columnSortDia"
            :columns="cols"
            @column-change="columnChange"
        ></columnSortDia>
    </div>
</template>
<script>
import _ from 'lodash'
import echarts from 'echarts'
import columnSortDia from '@/components/columnSortDialog'
import sellSvc from 'services/sell'
import commonSvc from 'services/common'

export default {
    name: 'analysis',
    props: {
        form: {
            type: Object,
            default: () => {}
        },
        channelId: {
            type: String,
            default: ''
        },
        hasQuery: {
            type: Boolean,
            default: true
        }
    },
    data() {
        return {
            query: {
                lvl2VisitSourceList: [],
                lvl1VisitSource: ''
            },
            tableQuery: {
                orderColName: null,
                orderAsc: null
            }, // 表格查询条件
            head: {
                sex: '性别'
            },
            tableData: {},
            loadingChart: false,
            loadingTable: false,
            chartData: null,
            // tableData: null,
            level1: [], // 一级流量来源
            lvl1Name: '',
            level2: [], // 二级流量来源
            lvl2Names: '',
            userShortCuts: [], // 用户快捷筛选数组
            short: null, // 选中快捷方式值
            filterCol: []
        }
    },
    computed: {
        shortcuts() {
            return this.userShortCuts.map((item, index) => {
                if (!item.itemTitle) {
                    item.itemTitleCopy = `快捷方式${index + 1}`
                } else if (item.itemTitle.length > 3) {
                    // `${item.itemTitle.substr(0, 3)}..`
                    item.itemTitleCopy = item.itemTitle
                } else {
                    item.itemTitleCopy = item.itemTitle
                }
                return item
            })
        },
        defaultSort() {
            let { orderAsc: order, orderColName: prop } = this.tableData
            const alias = {
                ASC: 'ascending',
                DESC: 'descending'
            }
            order = alias[order]
            return {
                prop,
                order
            }
        },
        filterEmptyCol() {
            const headNameCol = this.tableData.headNameCol || {}
            if (!headNameCol) {
                return []
            }
            return Object.keys(headNameCol).reduce((prev, cur) => {
                if (headNameCol[cur]) {
                    prev.push({
                        prop: cur,
                        label: headNameCol[cur],
                        hidden: false
                    })
                }
                return prev
            }, [])
        },
        cols() {
            if (!this.filterCol || !this.filterCol.length) {
                return this.filterEmptyCol
            }
            return _.cloneDeep(this.filterCol)
        },
        colsView() {
            return this.cols.filter(item => !item.hidden)
        }
    },
    watch: {
        channelId: {
            immediate: true,
            handler(newVal) {
                this.getVisitData('1', newVal)
                this.getVisitData('2', newVal)
            }
        }
    },
    components: {
        columnSortDia
    },
    methods: {
        columnChange(value) {
            this.filterCol = value
            this.$nextTick(() => {
                this.$refs.analysisTable.doLayout()
            })
        },
        handleSortChange({ prop, order }) {
            // 表格排序
            const alias = {
                ascending: 'ASC',
                descending: 'DESC'
            }
            this.tableQuery.orderAsc = alias[order]
            this.tableQuery.orderColName = prop
            this.getCompetingData('Table', true)
        },
        handleChange() {
            this.short = null
            this.handleSearch()
        },
        handleShortChange() {
            try {
                const shortTarget =
                    this.shortcuts.find(item => item.id == this.short) || {}
                const target = JSON.parse(shortTarget.stringValue || '{}')
                const { lvl2VisitSourceList = [], lvl1VisitSource = '' } =
                    target || {}
                this.query.lvl2VisitSourceList = lvl2VisitSourceList
                this.query.lvl1VisitSource = lvl1VisitSource
                this.handleSearch()
            } catch (error) {
                console.log(error)
            }
        },
        handleSearch() {
            this.getAllData()
        },
        handleSaveFilter() {
            if (this.userShortCuts.length >= 5) {
                this.$message.warning('最多只能保存五个筛选快捷选项')
                return
            }
            const {
                lvl2VisitSourceList = [],
                lvl1VisitSource = ''
            } = this.query
            if (
                (!lvl2VisitSourceList || !lvl2VisitSourceList.length) &&
                !lvl1VisitSource
            ) {
                this.$message.warning('二级流量来源或一集流量来源至少选择一项')
                return
            }
            this.$prompt('请输入快捷名称', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                inputPattern: /^[\S]{1,10}$/,
                inputErrorMessage: '请输入1-10个字符'
            })
                .then(async ({ value }) => {
                    // const nameLvl2 = this.level2
                    //     .filter(item => lvl2VisitSourceList.includes(item.id))
                    //     .map(item => item.sourceName)
                    //     .join(',')
                    // const nameLvl1 = this.level1
                    //     .filter(item => lvl1VisitSource === item.id)
                    //     .map(item => item.sourceName)
                    //     .join(',')
                    // const itemTitle =
                    //     nameLvl2 && nameLvl1
                    //         ? nameLvl2 + '+' + nameLvl1
                    //         : nameLvl2 || nameLvl1
                    const { data } = await commonSvc.saveUserShortcut({
                        id: null,
                        itemValue: 'selling-product',
                        itemTitle: value,
                        stringValue: JSON.stringify({
                            lvl2VisitSourceList,
                            lvl1VisitSource
                        })
                    })
                    this.getUserShortcuts()
                })
                .catch(() => {
                    // this.$message({
                    //     type: 'info',
                    //     message: '取消输入'
                    // })
                })
        },
        async getAllData() {
            this.filterCol = null
            this.getCompetingData()
            this.getCompetingData('Table')
        },
        async getCompetingData(type = 'Chart', isSort = false) {
            // 获取表格or图数据
            this[`loading${type}`] = true
            let query = {}
            ;['spuId', 'startDay', 'endDay'].forEach(item => {
                if (this.form[item]) {
                    query[item] = this.form[item]
                }
            })
            query = {
                ...query,
                ...this.query
            }
            if (type === 'Table') {
                query = {
                    ...query,
                    ...this.tableQuery
                }
            }
            // ;[
            //     'spuId',
            //     'startDay',
            //     'endDay',
            //     'lvl2VisitSourceList',
            //     'lvl1VisitSource',
            //     'orderColName',
            //     'orderAsc'
            // ].forEach(item => {
            //     if (this.form[item]) {
            //         query[item] = this.form[item]
            //     }
            // })
            const { data } = await sellSvc[`getSpu${type}Data`](query)
            if (type === 'Chart') {
                this.chartData = data
                this.initChart(data)
            } else {
                this.tableData = data
                if (!isSort) {
                    this.$nextTick(() => {
                        let { orderAsc, orderColName } = this.tableData
                        const alias = {
                            ASC: 'ascending',
                            DESC: 'descending'
                        }
                        this.tableQuery.orderColName = orderColName
                        this.tableQuery.orderAsc = orderAsc
                        this.$refs.analysisTable && this.$refs.analysisTable.sort(
                            orderColName,
                            alias[orderAsc]
                        )
                    })
                }
            }
            this[`loading${type}`] = false
        },
        initChart(data) {
            this.myChart = echarts.init(
                document.getElementById('competing-goods-analysis-chart')
            )
            if (
                data &&
                (!data.spuAliasList.length || data.spuAliasList[0] === '') &&
                !data.visitDataList.length &&
                !data.visitPercentList.length
            ) {
                this.myChart.clear()
                this.myChart.showLoading({
                    text: '没哟查询到图表数据',
                    color: 'transparent',
                    textColor: '#8a8e91',
                    maskColor: 'transparent'
                })
                return
            }
            this.myChart.hideLoading()
            this.myChart.clear()
            const option = {
                // backgroundColor: '#000000',
                grid: {
                    // containLabel: true,
                    right: '280px',
                    left: '80px'
                },
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'cross',
                        crossStyle: {
                            color: '#999'
                        }
                    }
                },
                toolbox: {
                    feature: {}
                },
                color: [
                    '#DF5A68',
                    '#E6A23C',
                    '#3595E1',
                    '#39A36B',
                    '#7158DE',
                    '#6FD3D1',
                    '#D36FCF',
                    '#EFD455',
                    '#E48045'
                ],
                legend: {
                    data: [],
                    right: '5%',
                    top: '10%',
                    width: '100px',
                    orient: 'vertical'
                    // formatter: function(name) {
                    //     return name +'\r'
                    // }
                },
                xAxis: [
                    {
                        type: 'category',
                        data: [],
                        axisLabel: {
                            interval: 0
                        },
                        axisPointer: {
                            type: 'shadow'
                        },
                        axisLine: {
                            show: false
                        },
                        axisTick: {
                            show: false
                        }
                    }
                ],
                yAxis: [
                    {
                        type: 'value',
                        name: '流量',
                        min: 0,
                        max: 250,
                        interval: 50,
                        axisLabel: {
                            formatter: '{value}',
                            color: '#A7B6C3'
                        },
                        axisLine: {
                            show: false
                        },
                        axisTick: {
                            show: false
                        },
                        splitLine: {
                            lineStyle: {
                                color: '#A7B6C3'
                            },
                            width: 0
                        }
                    },
                    {
                        type: 'value',
                        name: '百分比',
                        min: 0,
                        max: 100,
                        interval: 10,
                        axisLabel: {
                            formatter: '{value}%',
                            color: '#A7B6C3'
                        },
                        axisLine: {
                            show: false
                        },
                        axisTick: {
                            show: false
                        }
                    }
                ],
                series: []
            }
            const names = data.visitDataList.map(item => item.sourceName)
            names.push(data.lvl1VisitSourceName + '占比')
            option.legend.data = names
            option.xAxis[0].data = data.spuAliasList
            option.yAxis[0].max = data.maxVisitNum
            option.yAxis[0].interval = data.maxVisitNum / 10
            option.series = data.visitDataList.map(item => {
                const target = {
                    name: item.sourceName,
                    type: 'bar',
                    data: item.visitNumList,
                    barMaxWidth: '50',
                    itemStyle: {
                        normal: {
                            label: {
                                show: true, //开启显示
                                position: 'top', //在上方显示
                                textStyle: {
                                    //数值样式
                                    color: '#606266',
                                    fontSize: 10
                                }
                            }
                        }
                    },
                    barGap: '5%'
                }
                return target
            })

            option.series.push({
                name: data.lvl1VisitSourceName + '占比',
                type: 'line',
                smooth: true,
                yAxisIndex: 1,
                data: data.visitPercentList,
                label: {
                    formatter: '{value}%'
                },
                itemStyle: {
                    normal: {
                        label: {
                            show: true, //开启显示
                            position: 'top', //在上方显示
                            formatter: function(item) {
                                return item.value + '%'
                            },
                            textStyle: {
                                //数值样式
                                color: '#E48045',
                                fontSize: 14
                            }
                        },
                        color: '#E48045'
                    }
                }
            })
            this.myChart.setOption(option)
        },
        showColumn() {
            this.$refs.columnSortDia.visible = true
        },
        async getVisitData(level, channelId) {
            if(!channelId) {
                this[`level${level}`] = []
                return
            }
            const { data } = await sellSvc.getSellVisit({
                level,
                channelId
            })
            this[`level${level}`] = data
        },
        async getUserShortcuts() {
            const query = {
                itemValue: 'selling-product'
            }
            const { data } = await commonSvc.getUserShortcuts(query)
            this.userShortCuts = data || []
        }
    },
    beforeDestroy() {
        this.myChart && this.myChart.dispose()
    },
    mounted() {
        this.getUserShortcuts()
        window.onresize = () => {
            //  根据窗口大小调整曲线大小
            this.myChart.resize()
        }
    },
    activated() {
        window.onresize = () => {
            //  根据窗口大小调整曲线大小
            this.myChart && this.myChart.resize()
        }
    }
}
</script>
<style lang="scss">
.el-select__tags .el-tag--info {
    white-space: nowrap;
    overflow: hidden;
    max-width: 140px;
    text-overflow: ellipsis;
}
.el-radio-button__inner {
    max-width: 100px;
    padding: 12px 5px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 12px;
    font-weight: 400;
}
</style>
<style lang="scss" scoped>
.competing-goods-analysis {
    .w240 {
        width: 240px;
    }
    .mr20 {
        margin-right: 20px;
    }
    .mb20 {
        margin-bottom: 20px;
    }
    .query-title {
        display: flex;
        align-items: center;
        margin-right: 80px;
        height: 40px;
    }
    &-query {
        display: inline-flex;
        flex-wrap: wrap;
    }
    .el-button {
        border-radius: 8px;
    }
    #competing-goods-analysis-chart {
        width: 100%;
        height: 320px;
    }
    ::v-deep.el-table {
        .has-gutter th {
            background-color: #f7f9fc;
            font-size: 14px;
            font-weight: 500;
            color: #606266;
        }
        .expanded + tr:not(.el-table__row) > td {
            padding: 0;
            background: #f7f9fc;
            &:hover {
                background: #f7f9fc !important;
            }
            .el-pagination * {
                background: transparent;
            }
        }
        .el-table--fit {
            background-color: #f7f9fc;
        }
    }
    ::v-deep.el-tabs__nav-wrap {
        padding: 0 116px;
        .el-tabs__item {
            color: #909399;
        }
    }
    ::v-deep.el-table th.is-leaf {
        background-color: #f7f9fc;
        font-size: 14px;
        font-weight: 500;
        color: #606266;
    }
    ::v-deep.el-tabs__header {
        margin: 0;
    }
    ::v-deep.el-tabs__content .el-table tr {
        background-color: #f7f9fc;
    }
    .custom-col-content {
        width: 100%;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }
}
</style>
