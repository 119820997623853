<template>
    <div :id="id" class="custom-editor"></div>
</template>

<script>
import E from 'wangeditor'
import _ from 'lodash'

export default {
    name: 'gb-editor',
    model: {
        prop: 'value',
        event: 'input'
    },
    props: {
        value: {
            type: [String],
            default: ''
        },
        id: {
            type: [String],
            default: 'editor'
        }
    },
    watch: {
        value: {
            immediate: true,
            handler(newVal) {
                this.content = newVal
            }
        }
    },
    data() {
        return {
            content: '',
            editor: null
        }
    },
    methods: {
        handleChange: _.debounce((html) => {
            const vm = window.vmThis
            vm.$emit('input', vm.content)
        }, 300),
        createEditor() {
            const editor = new E('#editor')
            // 配置 onchange 回调函数，将数据同步到 vue 中
            editor.config.onchange = (newHtml) => {
                this.content = newHtml
                this.handleChange()
            }
            // 配置菜单
            editor.config.menus = [
                // 'head',
                'bold',
                'fontSize',
                'fontName',
                'italic',
                'underline',
                'strikeThrough',
                'indent',
                'lineHeight',
                'foreColor',
                'backColor',
                // 'link',
                'list',
                'todo',
                'justify',
                'quote',
                // 'emoticon',
                // 'image',
                // 'video',
                // 'table',
                // 'code',
                'splitLine',
                'undo',
                'redo',
            ]
            // 创建编辑器
            editor.create()
            // 初始化内容
            editor.txt.html(this.content)
            this.editor = editor
        }
    },
    created() {
        window.vmThis = this
    },
    mounted() {
        this.createEditor()
        // window.vmThis = this
    },
    beforeDestroy() {
        delete window.vmThis
        this.editor.destroy()
        this.editor = null
    }
}
</script>
<style lang="less">
</style>
<style lang="scss" scoped>
::v-deep.mce-flow-layout-item.mce-last {
    display: none;
}
</style>
